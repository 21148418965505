import request from '../utils/request'
// 用户登录
export function login(data) {
  console.log(data)
    return request({
      url: '/auth/login',
      method: 'post',
      data
    });
}
// 游客登录
export function touristLogin(data) {
  let info = JSON.stringify({isCode: false, planId: null})
  console.log(info)
    return request({
      url: '/auth/tourist/login',
      method: 'post',
      data:info
    });
}
// 获取验证码
export function getLoginCode(params) {
  return request({
    url: '/user/login/code',
    method: 'get',
    params
  });
}
// 获取注册验证码
export function getCodes(params) {
  return request({
    url: '/user/register/code',
    method: 'get',
    params
  });
}
// 注册
export function register(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data
  });
}
// 手机号登录
export function loginTel(data) {
  // console.log(data)
  let info = new FormData();
  info.append('telephone', data.telephone);
  info.append('code', data.code)
  console.log(info)
  return request({
    url: '/auth/telephone/login',
    method: 'post',
    data: info
  });
}
// 根据计划获取量表列表
export function getGaugeList2(params) {
    return request({
      url: '/plan/gauge/user/list',
      method: 'get',
      params
    });
}
// 根据计划获取量表列表
export function getGaugeList(params) {
  return request({
    url: '/plan/gauge/user/listAll',
    method: 'get',
    params
  });
}
// 获取量表详情
export function getGaugeDetail(id,params) {
  return request({
    url: `/gauge?id=${id}`,
    method: 'get',
    params
  });
}
// 获取量表题目
export function getGaugeTitle(params) {
  return request({
    url:'/plan/problem/user/list',
    method: 'get',
    params
  });
}
// 提交单题答案
export function submitProblemOne(data) {
  return request({
    url:'/plan/save/result',
    method: 'post',
    data
  });
}
// 用户点击提交按钮
export function submitAll(data) {
  return request({
    url: '/plan/result/submit',
    method: 'post',
    data
  });
}
// 获取测评报告
export function getReport(params) {
  return request({
    url: '/result/report',
    method: 'get',
    params
  });
}