<template>
    <div class="report">
        <!-- 报告页面 -->
        <img src="../../../assets/images/service/gauge/reportLogo.png" alt="">
        <div class="reportContent">
            <div class="reportTop">
                <span class="span2">{{ reportInfo.nickname }}的报告</span>
                <span class="span2">报告生成时间{{ reportInfo.reportDate }}</span>
            </div>
            <div class="">
                <p class="title p4">个人信息</p>
                <p> <span class="span2">姓名：</span> <span class="span2 text">{{ reportInfo.nickname }}</span> </p>
                <p> <span class="span2">性别：</span> <span class="span2 text">{{ reportInfo.sex }}</span> </p>
                <p> <span class="span2">出生日期：</span> <span class="span2 text">{{ reportInfo.birthday }}</span> </p>
                <p> <span class="span2">年龄：</span> <span class="span2 text">{{ reportInfo.age }}</span> </p>
                <p> <span class="span2">电话：</span> <span class="span2 text">{{ reportInfo.telephone }}</span> </p>
            </div>
            <div class="">
                <p class="title p4">测评信息</p>
                <p> <span class="span2">量表名称：</span> <span class="span2 text">{{ reportInfo.gaugeName }}</span> </p>
                <p> <span class="span2">测评时间</span> <span class="span2 text">{{ reportInfo.date }}</span> </p>
                <p> <span class="span2">量表介绍：</span> <span class="span2 text">{{ reportInfo.memo }}</span> </p>
                <p> <span class="span2">测评地点：</span> <span class="span2 text">{{ reportInfo.deptName }}</span> </p>
            </div>
            <div v-if="dimensionList.length > 0">
                <p class="title p4">因子图表</p>
                <div class="icon" id="dimensionIcon">

                </div>
            </div>
            <div v-if="dimensionList.length > 0">
                <p class="title p4">因子得分</p>
                <div class="table">
                    <el-table :data="dimensionList" style="width: 100%" border
                        :header-cell-style="{ background: ' #F9F9F9' }" size="small">
                        <el-table-column label="名称">
                            <template slot-scope="scope">
                                <span>{{ scope.row.label }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="得分">
                            <template slot-scope="scope">
                                <span>{{ scope.row.value.score }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="程度">
                            <template slot-scope="scope">
                                <span v-if="scope.row.value.normList.length > 0 && scope.row.value.normList[0].degree">{{
                                    scope.row.value.normList[0].degree
                                }}</span>
                                <span v-else>--</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="参考值">
                            <template slot-scope="scope">
                                <span v-if="scope.row.value.normList.length > 0 && scope.row.value.normList[0].reference">{{
                                    scope.row.value.normList[0].reference }}</span>
                                <span v-else>--</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div v-if="countList.length > 0">
                <p class="title p4">单题计数图表</p>
                <div class="icon" id="countIcon">

                </div>
            </div>
            <div v-if="countList.length > 0">
                <p class="title p4">单题计数</p>
                <div class="table">
                    <el-table :data="countList" style="width: 100%" border :header-cell-style="{ background: ' #F9F9F9' }"
                        size="small">
                        <el-table-column label="名称">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量">
                            <template slot-scope="scope">
                                <span>{{ scope.row.count }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="proposal">
                <p class="title p4">测评结果和辅导建议</p>
                <div c v-for="item in dimensionList" :key="item.id">
                    <p>{{ item.label }}
                        <span>({{ item.value.score }})</span>
                    </p>
                    <p class="text"><span
                            v-if="item.value.normList.length > 0 && item.value.normList[0].memoIsShow === true">{{
                                item.value.normList[0].memo
                            }}</span>
                    </p>
                    <p class="text"><span
                            v-if="item.value.normList.length > 0 && item.value.normList[0].proposalIsShow === true">{{
                                item.value.normList[0].proposal
                            }}</span>
                    </p>
                </div>
                <div v-for="item in countList" :key="item.id">
                    <p>{{ item.name }}
                        <span>({{ item.count }})</span>
                    </p>
                    <p><span v-if="(item.countDetailList.length > 0 && item.countDetailList[0].memoIsShow === 1)">{{
                        item.countDetailList[0].memo
                    }}</span>
                    </p>
                    <p><span v-if="(item.countDetailList.length > 0 && item.countDetailList[0].proposalIsShow === 1)">{{
                        item.countDetailList[0].proposal
                    }}</span>
                    </p>
                </div>
                <p>结果声明：本测试结果只反映受测者当时的状态，内容仅供参考，如有疑问，请拨打曼扉心理咨询热线或咨询专业心理咨询师。</p>
            </div>
        </div>
        <reportBottomVue></reportBottomVue>
        <div class="backBtn" @click="back">
            <span class="span2">返回列表</span>
        </div>
</div>
</template>

<script>

import reportBottomVue from '@/components/bottom/reportBottom.vue'
import { getReport } from '@/api/gauge'
export default {
    components: { reportBottomVue },
    data() {
        return {
            reportInfo: {},
            dimensionList: [], // 维度数组
            countList: [], // 单题计数数组
            xList: [], // x轴的数组
            yList: [],  //y轴的数组
            xList2: [], // x轴的数组
            yList2: [],  //y轴的数组
            radarList2: [], // 雷达图的数据
            radarList: [], // 雷达图的数据
            list: [],
            list2: [],
            type: 1, // 因子图表类型
            chart: 1, //单题计数图表类型
        }
    },
    methods: {
        async report() {
            const res = await getReport({ id: this.$route.query.reportId });
            console.log(res);
            if (res.code === 200) {
                this.reportInfo = res.data;
                let dimensionInfo = res.data.dimensionNormVOMap;
                this.countList = res.data.countDetailReportVOList;
                this.type = res.data.type;
                dimensionInfo.forEach(item => {
                    // console.log(Object.keys(item));
                    this.dimensionList.push({
                        label: Object.keys(item)[0],
                        value: item[Object.keys(item)[0]]
                    })
                })
                if (this.reportInfo.sex === 1) {
                    this.reportInfo.sex = '男'
                } else if (this.reportInfo.sex === 2) {
                    this.reportInfo.sex = '女'
                } else if (this.reportInfo.sex === 3) {
                    this.reportInfo.sex = '未设置'
                } else {
                    this.reportInfo.sex = '未设置'
                }


                // 处理因子图表所需的数据------------------------
                let xData = [];
                let yData = [];
                this.dimensionList.forEach(item => {
                    if (item.value.isShow === 'true' || item.value.isShow) {
                        yData.push(item.label);
                        this.radarList.push({ name: item.label })
                        xData.push(item.value.score);
                    }
                })
                this.xList = [...xData];
                this.yList = [...yData];
                this.yList.forEach((item, index) => {
                    this.list.push({ name: item, value: this.xList[index] })
                })
                this.echartsInit();
                // -------------------------------------------------

                // 处理单题计数图表所需的数据-------------------
                let xData2 = [];
                let yData2 = [];
                this.countList.forEach(item => {
                    // console.log(item)
                    // if (item.value.isShow === 'true' || item.value.isShow) {
                    this.radarList2.push({ name: item.name })
                    yData2.push(item.name);
                    xData2.push(item.count);
                    // }

                })
                this.xList2 = [...xData2];
                this.yList2 = [...yData2];
                this.yList2.forEach((item, index) => {
                    this.list2.push({ name: item, value: this.xList[index] })
                })
                this.echartsInit2();
                // --------------------------------------
            }
        },
        //因子图表
        echartsInit() {
            console.log(this.xList);
            console.log(this.yList)
            let option = {};
            if (this.type === 1) {
                option = {
                    // title: {
                    //     text: "图表显示"
                    // },
                    tooltip: {},
                    xAxis: {
                    },
                    yAxis: { data: this.yList },
                    series: [{
                        name: '分值',
                        type: 'bar',
                        data: this.xList,
                        color: '#5B87A3'
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                };
            } else if (this.type === 2) {
                // icon = 'line'
                option = {

                    tooltip: {},
                    xAxis: {

                    },
                    yAxis: { data: this.yList },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.xList,
                        color: '#5B87A3'
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                };
            } else if (this.type === 3) {
                option = {
                    tooltip: {},
                    xAxis: {
                    },
                    yAxis: { data: this.yList },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.xList,
                        color: '#5B87A3',
                        areaStyle: {}
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                };
            } else if (this.type === 4) {
                option = {
                    radar: {
                        axisName: {
                            color: '#555'
                        },
                        indicator: this.radarList.map(function (item) {
                            item.name = item.name;
                            item.max = 10;
                            return item;
                        }),
                        splitArea: {
                            areaStyle: {
                                color: ['#fff'],
                                shadowColor: 'rgba(0, 0, 0, 0.2)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: ['#D5DEFD', '#D5DEFD', '#D5DEFD']
                            }
                        }
                    },
                    series: [
                        {
                            name: '多边雷达图',
                            type: 'radar',
                            symbolSize: 10,
                            areaStyle: {
                                normal: {
                                    color: '#DCE8ED'
                                }
                            },
                            data: [
                                {
                                    value: this.xList,
                                    itemStyle: {
                                        normal: {
                                            color: '#2FC0E0'
                                        }
                                    },
                                    label: {
                                        show: true,
                                        formatter: this.xList
                                    }
                                }
                            ]
                        }]
                }
            }

            this.$nextTick(() => {
                let myEchart = this.$echarts.init(document.getElementById("dimensionIcon"));
                myEchart.setOption(option);
            });
        },
        //单题计数图表
        echartsInit2() {
            console.log(this.xList2);
            console.log(this.yList2)
            let option = {};
            if (this.chart === 1) {
                option = {
                    tooltip: {},
                    xAxis: {
                    },
                    yAxis: {
                        data: this.yList2
                    },
                    series: [{
                        name: '分值',
                        type: 'bar',
                        data: this.xList2,
                        color: '#5B87A3'
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },

                };
            } else if (this.chart === 2) {
                option = {
                    tooltip: {},
                    xAxis: {

                    },
                    yAxis: { data: this.yList2 },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.xList2,
                        color: '#5B87A3'
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },

                };
            } else if (this.chart === 3) {
                option = {
                    tooltip: {},
                    xAxis: {
                    },
                    yAxis: { data: this.yList2 },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.xList2,
                        color: '#5B87A3',
                        areaStyle: {}
                    }],
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },

                };
            } else if (this.chart === 4) {
                option = {
                    radar: {
                        axisName: {
                            color: '#555'
                        },
                        indicator: this.radarList2.map(function (item) {
                            item.name = item.name;
                            item.max = 10;
                            return item;
                        }),
                        splitArea: {
                            areaStyle: {
                                color: ['#fff'],
                                shadowColor: 'rgba(0, 0, 0, 0.2)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: ['#D5DEFD', '#D5DEFD', '#D5DEFD']
                            }
                        }
                    },
                    series: [
                        {
                            name: '多边雷达图',
                            type: 'radar',
                            symbolSize: 10,
                            areaStyle: {
                                normal: {
                                    color: '#DCE8ED'
                                }
                            },
                            data: [
                                {
                                    //   value: [120,130,120,140,141,121,131,151,124,136,133,166],
                                    value: this.xList2,
                                    //   value: option.map(function (item) {,
                                    //     return item.sum;
                                    //   }),
                                    itemStyle: {
                                        normal: {
                                            color: '#2FC0E0'
                                        }
                                    },
                                    label: {
                                        show: true,
                                        formatter: this.xList2
                                    }
                                }
                            ]
                        }]
                }
            }
            this.$nextTick(() => {
                let myEchart = this.$echarts.init(document.getElementById("countIcon"));
            myEchart.setOption(option);
            });
            
        },
        // 返回报告列表
        back() {
            this.$router.push('/test/gauge')
        }
    },
    created() {
        this.report();
    }
}
</script>

<style lang="scss" scoped>
.report {
    img {
        height: 40px;
    }

    .reportContent {
        margin-top: 20px;
        padding: 20px 0px;
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;

        .reportTop {
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;

            background: var(--login-color);

            span {
                font-size: 14px;
                color: white;

                // font-weight: 600;
                &:first-child {
                    // font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        .proposal {
            p {
                font-size: 14px;
                // color: var(--titleFont-color);
            }
            .title.p4{
                font-size: 16px;
            }
        }

        p {
            color: var(--titleFont-color);
            margin-top: 10px;
        }

        .title {
            margin-top: 40px;
            font-weight: 600;
        }

        .text {
            color: var(--memoFont-color);
        }

        .icon {
            width: 100%;
            height: 400px;
        }

        .table {
            margin-top: 20px;
        }
    }

    .backBtn {
        cursor: pointer;
        margin-bottom: 40px;
        width: 100%;
        padding: 10px 0px;
        text-align: center;
        background: var(--custom-color);

        span {
            font-weight: 600;
            color: var(--headFont-color);
        }
    }
}</style>