<template>
    <div class="bottomBox">
       
        <div class="right">
            <div class="top">
                <span class="telText">咨 热
                <br/>
                询 线</span>
            <span class="telephone">028-87057733</span>
        </div>
            
            <span class="name">成 都 锦 江 德 慈 曼 扉 诊 所 有 限 公 司</span>
            <span class="name2">Chengdu Jinjiang DecimanFei Clinic Co., Ltd</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.bottomBox {
    height: 130px;
    width: 100%;
    padding-top: 20px;
    // background: rebeccapurple;
    display: flex;
    // align-items: center;
    // border-top: 1px solid #DDDDDD;
    // margin-top: 10px;
    .left {
        width: 70px;
        height: 70px;
        // background: #CECECE;
        img{
            width: 100%;
        }
    }

    .right {
        // margin-left: 20px;
        height: 100px;
        span{
            display: block;
            font-size: 14px;
        }
        .telText{
            font-size: 12px;
        }
        .top{
            display: flex;
            align-items: center;
            color: var(--login-color);
            border-bottom: 1px solid #333;
            padding-bottom: 3px;
            margin-bottom: 3px;
            span{
                font-weight: 600;
            }
            .telephone{
                font-size: 20px;
                margin-left:15px;
            }
        }
        .name{
            font-size: 16px;
        }
        .name2{
            font-size: 12px;
        }
    }
}
</style>